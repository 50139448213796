export const HlsjsRobustness = {
	BUFFER_LEVEL_PRELOAD: 12,
	BUFFER_LEVEL_PLAYBACK: 60,
	MANIFEST_RETRY_ATTEMPTS: 2,
	MANIFEST_RETRY_DELAY: 1000,
	LEVEL_RETRY_TIMEOUT: 2000,
	LEVEL_RETRY_DELAY: 500,
	FRAGMENT_RETRY_TIMEOUT: 2000,
	FRAGMENT_RETRY_DELAY: 500,
	FATAL_ERROR_RECOVERY_ATTEMPTS: 3,
};
