import { createHlsPlugin, hls, HLS } from './HlsPlugin';

export { createHlsjsAdapter, createHlsjsPlugin, hlsjs, HLSJS } from '@cbsinteractive/avia-js-hlsjs';
export type { HlsjsAdapterOptionsInterface, HlsjsDependenciesInterface } from '@cbsinteractive/avia-js-hlsjs';
export * from './HlsPlugin';
export type { HlsPluginOptionsInterface } from './HlsPluginOptionsInterface';

// UMD Aliases
export const ID = HLS;
export const createPlugin = createHlsPlugin;
export const plugin = hls;
