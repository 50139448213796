import type { PlaybackAdapterDelegateInterface, PlaybackAdapterInterface, PluginConfigInterface, PluginInterface, PluginServicesInterface } from '@cbsinteractive/avia-js';
import { createHlsjsAdapter, HLSJS, isHlsjsSupported } from './HlsjsAdapter';
import { HlsjsPluginOptionsInterface } from './iface/HlsjsPluginOptionsInterface';

export function createHlsjsPlugin(services: PluginServicesInterface, options: HlsjsPluginOptionsInterface): PluginInterface {
	const { avia, player } = services;

	player.registerAdapter<PlaybackAdapterInterface, PlaybackAdapterDelegateInterface>({
		id: HLSJS,
		role: [avia.AdapterRole.PLAYBACK],
		options,
		factory: createHlsjsAdapter,
		isSupported: isHlsjsSupported,
	});

	return {
		getId() {
			return HLSJS;
		},

		destroy() { },
	};
}

export function hlsjs(options: HlsjsPluginOptionsInterface = {}): PluginConfigInterface {
	return {
		id: HLSJS,
		factory: createHlsjsPlugin,
		options,
	};
}
