import type { PluginConfigInterface, PluginInterface, PluginServicesInterface } from '@cbsinteractive/avia-js';
import { createHlsjsPlugin, HLSJS } from '@cbsinteractive/avia-js-hlsjs';
import type { HlsAdapterId, HlsPluginOptionsInterface } from './HlsPluginOptionsInterface';

export const HLS = 'hls';

export function createHlsPlugin(services: PluginServicesInterface, options: HlsPluginOptionsInterface = {}): PluginInterface {
	const createOptions = (id: HlsAdapterId) => {
		const opts = options.adapter?.[id];
		const dependencies = options.dependencies || {};
		return Object.assign({ debug: options.debug }, opts, { dependencies });
	};

	const plugins = [
		createHlsjsPlugin(services, createOptions(HLSJS)),
	];

	return {
		getId() {
			return HLS;
		},

		destroy() {
			plugins.map(plugin => plugin.destroy?.());
		},
	};
}

export function hls(options: HlsPluginOptionsInterface = {}): PluginConfigInterface {
	return {
		id: HLS,
		factory: createHlsPlugin,
		options,
	};
}
