import type { AudioTrackInterface, ErrorInfoInterface, MetadataCuepointInterface, MimeType, PlaybackAdapterContextInterface, PlaybackAdapterInterface, PlaybackMetricsInterface, QualityInterface, RangeInterface, RequestObjectType, ResponseInterface, TextTrackInterface, TextTrackMode } from '@cbsinteractive/avia-js';
import type Hls from 'hls.js';
import type { AudioTrackSwitchedData, AudioTracksUpdatedData, ErrorData, FragLoadedData, FragLoadingData, HlsListeners, LevelLoadedData, LevelSwitchedData, LoaderCallbacks, LoaderConfiguration, LoaderContext, LoaderResponse, LoaderStats, ManifestLoadedData, SubtitleTracksUpdatedData } from 'hls.js';
import { HlsjsRobustness } from './HlsjsRobustness';
import { HlsjsAdapterOptionsInterface } from './iface/HlsjsAdapterOptionsInterface';

export const HLSJS = 'hlsjs';

type HlsSdk = typeof Hls;

const LOAD_ERROR = 'avia-load-error' as keyof HlsListeners;

export function isHlsjsSupported(context: PlaybackAdapterContextInterface) {
	const { avia, resource, system } = context;
	const { Browser, MimeType, Os, PlaybackAdapterBase, Util } = avia;

	const isSafari = system.browser === Browser.SAFARI;
	const mimeType = Util.getResourceMimeType(resource) as MimeType;
	const mimeTypes = [MimeType.HLS, MimeType.HLS_ALT];
	const isM3u8 = mimeTypes.includes(mimeType);
	const fairplayDetected = !Util.isEmpty(resource.location.drm?.fairplay?.appCertUrl);

	// FairPlay not (yet) supported on hlsjs adapter
	if (isM3u8 && fairplayDetected) {
		return false;
	}

	// Chrome iOS
	const isIOS = system.os === Os.IOS;
	const unsupported = isM3u8 && isIOS && !isSafari;

	if (unsupported) {
		return false;
	}

	return PlaybackAdapterBase.canPlay(context, mimeTypes);
}

export async function createHlsjsAdapter(context: PlaybackAdapterContextInterface, options?: HlsjsAdapterOptionsInterface): Promise<PlaybackAdapterInterface> {
	const { avia, resource, system, playerOptions } = context;
	const { AudioTrackType, ErrorCode, PlayerHookType, PlayerError, PlaybackAdapterBase, RequestObjectType, Util } = avia;

	if (!system.global.Hls) {
		try {
			await Util.loadScript(
				{ url: options.dependencies?.hlsjs || '//cdnjs.cloudflare.com/ajax/libs/hls.js/1.5.8/hls.min.js' },
				context.playerOptions,
				() => {
					return system.global.Hls;
				},
			);
		}
		catch (error) {
			throw new PlayerError(ErrorCode.HLS_SDK_MISSING, 'Could not load hls.js SDK', error);
		}
	}

	const Hls: HlsSdk = system.global.Hls;
	const {
		Browser,
		ErrorMessage,
		Header,
		PerformanceMode,
		RequestCredentials,
		TextTrackMode,
		waitForEvent,
	} = avia;

	/**
	 * Hlsjs Adapter
	 */
	class HlsjsAdapter extends PlaybackAdapterBase {

		player: Hls;

		private isLiveStream: boolean = false;
		private totalDuration: number = 0;
		private framerate: number = NaN;
		private bandwidth: number = NaN;
		private qualityInterval: number = NaN;
		private withCredentials: boolean = false;
		private minAutoLevel: number = NaN;
		private maxAutoLevel: number = NaN;

		/**
		 * HlsjsAdapter
		 */
		constructor(context: PlaybackAdapterContextInterface, options: HlsjsAdapterOptionsInterface = {}) {
			super(context, options, false);

			this.logger.info(`hls.js version: ${Hls.version}`);

			const { performanceSettings, textTrackSettings } = context;
			const { playback } = resource;
			const defaults = Hls.DefaultConfig;

			// For MSE-based PS4s backBufferLength needs to be Infinity (livestream audio loss issue)
			const backBufferLength: number = system.browser === Browser.PLAYSTATION_4_MSE ? Infinity : performanceSettings.backBufferLength;

			const config = Util.merge(
				{
					debug: options.debug,
					autoStartLoad: false,
					liveSyncDurationCount: playback.liveEdgeSyncFragmentCount,
					maxBufferLength: performanceSettings.forwardBufferLength || defaults.maxBufferLength,
					backBufferLength: backBufferLength,
					maxMaxBufferLength: performanceSettings.topQualityForwardBufferLength || defaults.maxMaxBufferLength,
					manifestLoadingMaxRetry: HlsjsRobustness.MANIFEST_RETRY_ATTEMPTS,
					manifestLoadingRetryDelay: HlsjsRobustness.MANIFEST_RETRY_DELAY,
					levelLoadingRetryDelay: HlsjsRobustness.LEVEL_RETRY_DELAY,
					// levelLoadingTimeOut: HlsjsRobustness.LEVEL_RETRY_TIMEOUT,
					fragLoadingRetryDelay: HlsjsRobustness.FRAGMENT_RETRY_DELAY,
					// fragLoadingTimeOut: HlsjsRobustness.FRAGMENT_RETRY_TIMEOUT,
					cmcd: resource.cmcd.enabled ? resource.cmcd : null,
					capLevelToPlayerSize: !Number.isFinite(this.maxHeight) && this.qualityCappedToScreenSize,
					pLoader: this.createLoader(this, RequestObjectType.MANIFEST),
					fLoader: this.createLoader(this, RequestObjectType.SEGMENT),
					loader: this.createLoader(this, RequestObjectType.OTHER),
					ignoreDevicePixelRatio: playback.abr.ignoreDevicePixelRatio,
				},
				options.config,
			);

			if (performanceSettings.mode === PerformanceMode.LOW) {
				Object.assign(config, {
					maxBufferSize: 20e6,
					liveSyncDurationCount: 6,
					stretchShortVideoTrack: true,
					maxBufferHole: 2.5,
					maxStarvationDelay: 10,
					highBufferWatchdogPeriod: 5,
					maxFragLookUpTolerance: 2.5,
					nudgeOffset: 0.25,
					nudgeMaxRetry: 5,
				});
				playback.liveEdgeSyncFragmentCount = config.liveSyncDurationCount;
			}

			const xhrSetup = config.xhrSetup;
			config.xhrSetup = (xhr: XMLHttpRequest, url: string) => {
				xhr.withCredentials = this.withCredentials;
				xhrSetup?.(xhr, url);
			};

			const player = this.player = new Hls(config);

			// This tells hlsjs to use "showing" or "hidden" when selecting a text track.
			player.subtitleDisplay = textTrackSettings.native;

			const { Events } = Hls;
			player.on(Events.LEVEL_LOADED, this.onLevelLoaded);
			player.on(Events.LEVEL_SWITCHED, this.onLevelSwitched);
			player.on(Events.FRAG_LOADED, this.onFragLoaded);

			// TODO: Framerate is no longer calculable in hls.js 1.0.x
			// this.player.on(Hls.Events.FRAG_PARSED, this.onFragParsed);

			player.on(Events.SUBTITLE_TRACKS_UPDATED, this.onSubtitleTracksUpdated);
			player.on(Events.SUBTITLE_TRACK_SWITCH, this.onSubtitleTrackSwitch);

			player.on(Events.AUDIO_TRACKS_UPDATED, this.onAudioTracksUpdated);
			player.on(Events.AUDIO_TRACK_SWITCHED, this.onAudioTrackSwitched);
		}

		/**
		 *
		 */
		override getId() {
			return HLSJS;
		}

		/**
		 *
		 */
		override destroy(): Promise<void> {
			system.global.clearInterval(this.qualityInterval);

			const promise = waitForEvent(this.player, Hls.Events.MEDIA_DETACHING);
			this.player.destroy();
			return promise.then(() => {
				this.player = null;
				return super.destroy();
			});
		}

		private createLoader(adapter: HlsjsAdapter, objectType: RequestObjectType) {
			const Loader = Hls.DefaultConfig.loader;
			return class AviaLoader extends Loader {
				override load(context: LoaderContext, config: LoaderConfiguration, callbacks: LoaderCallbacks<LoaderContext>) {
					const { url, headers = {} } = context;
					const aes = adapter.context.resource.location.drm.aes;
					const isKeyRequest = !Util.isEmpty(aes) && url.includes(aes.provider) && aes.header;

					return Promise.resolve()
						.then(() => {
							const credentials = url.includes('akamaihd') && url.includes('csmil') ? RequestCredentials.INCLUDE : RequestCredentials.SAME_ORIGIN;
							const request = Util.createRequest(url, headers, credentials);

							if (isKeyRequest) {
								Object.assign(headers, aes.header);
							}

							return request;
						})
						.then(request => {
							if (adapter.delegate.hasHook(PlayerHookType.REQUEST)) {
								const metadata = {
									objectType: isKeyRequest ? RequestObjectType.KEY : objectType,
								};
								return adapter.delegate.applyHook(PlayerHookType.REQUEST, request, metadata);
							}

							return request;
						})
						.then(request => {
							Object.assign(context, request);
							adapter.withCredentials = request.credentials === RequestCredentials.INCLUDE;

							const onSuccess = callbacks.onSuccess;
							callbacks.onSuccess = async (response: LoaderResponse, stats: LoaderStats, context: LoaderContext, xhr: XMLHttpRequest) => {
								if (!adapter.delegate) {
									return;
								}

								if (adapter.delegate.hasHook(PlayerHookType.RESPONSE)) {
									response = await adapter.delegate.applyHook(PlayerHookType.RESPONSE, response as ResponseInterface);
								}

								if (response.url.includes('.ts')) {
									const cdn = Util.getHeader(Header.MULTI_CDN, xhr.getAllResponseHeaders());
									if (cdn != null) {
										adapter.delegate.cdnChange(cdn);
									}
								}

								onSuccess(response, stats, context, xhr);
							};
						})
						.then(() => {
							super.load(context, config, callbacks);
						});
				}
			};
		}

		/**
		 *
		 */
		override load() {
			return super.load()
				.then(async metadata => {
					this.player.on(Hls.Events.ERROR, this.onLoadError);

					this.player.attachMedia(this.video);
					await waitForEvent(this.player, Hls.Events.MEDIA_ATTACHED, LOAD_ERROR);

					this.player.loadSource(resource.location.mediaUrl);
					const manifest = await waitForEvent<ManifestLoadedData>(this.player, Hls.Events.MANIFEST_LOADED, LOAD_ERROR);

					this.initQualityInfo();
					this.initNativeTextTracks(manifest);

					const startTime = resource.playback.startTime;
					this.player.startLoad(Util.isValidPlayheadTime(startTime) ? startTime : -1);

					this.setBitrateRestrictionAtStartup();
					const data = await waitForEvent<FragLoadingData>(this.player, Hls.Events.FRAG_LOADING, LOAD_ERROR);

					this.player.off(Hls.Events.ERROR, this.onLoadError);
					this.player.on(Hls.Events.ERROR, this.onError);
					this.suppressErrors = false;

					metadata.fragment.mimeType = Util.getMimeType(data.frag.url);
					return metadata;
				});
		}

		/**
		 *
		 */
		override suspend(): void {
			this.player.stopLoad();
		}

		/**
		 *
		 */
		override resume(): void {
			this.player.startLoad();
		}

		/**
		 *
		 */
		override setTextTrack(track: TextTrackInterface): void {
			if (!track) {
				return;
			}

			if (this.textTrackSurface) {
				super.setTextTrack(track);
			}
			else {
				this.player.subtitleTrack = parseInt(track.id);
			}
		}

		/**
		 *
		 */
		override setTextTrackMode(mode: TextTrackMode): void {
			if (this.textTrackSurface) {
				super.setTextTrackMode(mode);
			}
			else {
				this.enabledSubtitles(mode);
				this.delegate.textTrackDisplayModeChange(mode);
			}
		}

		/**
		 *
		 */
		override setMinBitrate(value: number): void {
			super.setMinBitrate(value);

			this.player.config.minAutoBitrate = value - 1;

			this.updateQualityInfo();
		}

		/**
		 *
		 */
		override setMaxBitrate(value: number): void {
			super.setMaxBitrate(value);

			const reset = isNaN(value) || !Number.isFinite(value);
			this.player.capLevelToPlayerSize = reset ? this.qualityCappedToScreenSize : false;
			this.player.autoLevelCapping = reset ? -1 : Util.getMaxBitrateIndex(this.player.levels, value);

			this.updateQualityInfo();
		}

		/**
		 *
		 */
		override setAutoQualitySwitching(auto: boolean): void {
			this.player.loadLevel = auto ? -1 : this.player.nextLoadLevel;
		}

		/**
		 *
		 */
		override setQuality(quality: QualityInterface): void {
			this.player.loadLevel = quality.index;
		}
		/**
		 *
		 */
		override setAudioTrack(track: AudioTrackInterface) {
			if (!track) {
				this.logger.info('hls.js: Audio track is null, will not set.');

				return;
			}

			this.player.audioTrack = track.index;
			this.enabledSubtitles();
		}

		/**
		 *
		 */
		override getIsLiveStream() {
			return this.isLiveStream;
		}

		/**
		 *
		 */
		override getMetrics(): PlaybackMetricsInterface {
			return {
				droppedVideoFrames: NaN,
				framerate: this.framerate,
				bandwidth: this.bandwidth,
			};
		}

		/**
		 *
		 */
		override resize(): void {
			this.setBitrateRestrictionAtStartup();
		}

		/**
		 *
		 */
		protected override getSeekable(): RangeInterface {
			const duration = this.video.duration;
			const start = this.getIsLiveStream() ? duration - this.totalDuration : 0;

			return { start, end: duration };
		}

		protected override getLiveStreamUtcStart() {
			const { levels, currentLevel } = this.player;
			return levels[currentLevel]?.details?.fragments[0]?.programDateTime || NaN;
		}

		protected override getLiveStreamUtcTime() {
			return this.player.playingDate?.getTime() || NaN;
		}

		/**
		 *
		 */
		protected override onCueChange(event: Event): void {
			super.onCueChange(event, this.player.subtitleTracks[this.player.subtitleTrack]?.forced);
		}

		/**
		 *
		 */
		protected override onMetadataCuepoint(metadata: MetadataCuepointInterface): void {
			const cue = metadata.source;
			if (cue.endTime >= Number.MAX_VALUE) {
				cue.endTime = this.video.duration;
				metadata.endTime = cue.endTime;
			}
			super.onMetadataCuepoint(metadata);
		}

		/**
		 *
		 */
		private initQualityInfo() {
			this.qualityInterval = system.global.setInterval(() => {
				const { minAutoLevel, maxAutoLevel } = this.player;

				if (minAutoLevel === this.minAutoLevel && maxAutoLevel === this.maxAutoLevel) {
					return;
				}

				this.minAutoLevel = minAutoLevel;
				this.maxAutoLevel = maxAutoLevel;

				this.updateQualityInfo();
			}, 250);

			this.updateQualityInfo();
		}

		/**
		 *
		 */
		private updateQualityInfo() {
			this.qualities = this.player.levels.map(this.normalizeQuality);
			this.updateQualities();
		}

		private normalizeQuality = (item: any, index: number): QualityInterface => {
			return {
				index,
				bitrate: item.bitrate,
				width: item.width,
				height: item.height,
				codec: (item.attrs) ? item.attrs.CODECS : undefined,
				enabled: Util.validateQuality(item, this.minBitrate, this.maxBitrate, this.maxHeight, this.player.capLevelToPlayerSize && this.video),
				category: [],
			};
		};

		/**
		 *
		 */
		private initNativeTextTracks(manifest: ManifestLoadedData) {
			const hasManifestTracks = manifest.subtitles?.length;
			const { textTrackUrl } = this.context.resource.location;

			if (hasManifestTracks) {
				if (textTrackUrl) {
					this.logger.warn('Detected subtitles in the manifest. Ignoring resource\'s textTrackUrl');
				}

				if (manifest.captions) {
					this.logger.warn('Detected subtitles in the manifest. Ignoring manifest captions');
				}
				return;
			}

			this.textTrackSurface = this.createTextTrackSurface();
		}

		/**
		 *
		 */
		private selectSubtitles(track: TextTrackInterface) {
			this.textTrack = track;
			this.delegate.textTrackChange(track);
			this.enabledSubtitles();
		}

		/**
		 *
		 */
		private enabledSubtitles(mode: TextTrackMode = this.context.textTrackSettings.mode) {
			if (mode !== TextTrackMode.DISABLED) {
				this.listenToTextTracks(true);
				this.setTextTrack(this.textTrack);
			}
			else {
				const audioTrack = this.player.audioTracks[this.player.audioTrack];
				let index = -1;
				if (audioTrack) {
					const language = audioTrack.lang;
					index = this.player.subtitleTracks.findIndex(track => track.forced && track.lang === language);
				}

				this.listenToTextTracks(index !== -1);
				this.player.subtitleTrack = index;
			}
		}

		/**
		 *
		 */
		private setBitrateRestrictionAtStartup(): void {
			if (Number.isFinite(this.minBitrate)) {
				// HLS.js does not look at >= so we need to set the min just one below the actual bitrate. To ease developer confusion, handle inline.
				this.setMinBitrate(Math.max(this.minBitrate - 1, 0));
			}

			if (Number.isFinite(this.maxBitrate)) {
				this.setMaxBitrate(this.maxBitrate);
			}

			const startBitrate = resource.playback.abr.startBitrate;
			if (Number.isFinite(startBitrate)) {
				this.player.config.startLevel = Util.getMaxBitrateIndex(this.player.levels, startBitrate);
			}

			if (Number.isFinite(this.maxHeight)) {
				const index = Util.getMaxIndexForHeight(this.player.levels, this.maxHeight);
				if (!isNaN(index)) {
					this.player.capLevelToPlayerSize = false;
					this.player.autoLevelCapping = index;
				}
			}
			else {
				this.player.capLevelToPlayerSize = this.qualityCappedToScreenSize;
			}

			this.updateQualities();
		}

		/**
		 *
		 */
		private processError(data: ErrorData): ErrorInfoInterface {

			let code = ErrorCode.UNSPECIFIED_HLSJS_ERROR;

			switch (data.type) {
				case Hls.ErrorTypes.NETWORK_ERROR:
					return this.processNetworkErrors(data);

				case Hls.ErrorTypes.MEDIA_ERROR:
					return this.processMediaErrors(data);

				case Hls.ErrorTypes.MUX_ERROR:
					code = ErrorCode.HLSJS_MUX_ERROR;
				// no break is intended.
				default:
					// TODO: add message in AppResource for general error prefix to details
					const msg = this.getErrorMessage(`${data.details}`, data.fatal);
					return this.createError(code, msg, data, data.fatal);
			}
		}

		private processNetworkErrors(data: ErrorData): ErrorInfoInterface {
			const ErrorDetails = Hls.ErrorDetails;
			const message = data.fatal ? ErrorMessage.FATAL_PLAYBACK_NETWORK_ERROR : ErrorMessage.NONFATAL_PLAYBACK_NETWORK_ERROR;

			switch (data.details) {

				case ErrorDetails.LEVEL_LOAD_ERROR:
				case ErrorDetails.FRAG_LOAD_ERROR:
					return this.createError(ErrorCode.HLSJS_NETWORK_ERROR,
						message, data, data.fatal);

				case ErrorDetails.MANIFEST_PARSING_ERROR:
					return this.createError(ErrorCode.HLSJS_PARSE_ERROR,
						`${message} : ${data.details}`, data, data.fatal);

				default:
					return this.createError(ErrorCode.HLSJS_NETWORK_ERROR,
						`${message} : ${data.details}`, data, data.fatal);
			}
		}

		private processMediaErrors(data: ErrorData): ErrorInfoInterface {
			const ErrorDetails = Hls.ErrorDetails;
			const message = data.fatal ? ErrorMessage.FATAL_PLAYBACK_MEDIA_ERROR : ErrorMessage.NONFATAL_PLAYBACK_MEDIA_ERROR;

			switch (data.details) {
				case ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
					return this.createError(ErrorCode.HLSJS_SRC_NOT_SUPPORTED,
						`${message} : ${data.details}`, data, data.fatal);

				default:
					return this.createError(ErrorCode.HLSJS_MEDIA_ERROR,
						`${message} : ${data.details}`, data, data.fatal);
			}
		}

		/**
		 *
		 */
		private onLevelLoaded = (type: string, data: LevelLoadedData): void => {
			const { details } = data;
			this.isLiveStream = details.live;
			this.segmentDuration = details.averagetargetduration;
			this.totalDuration = details.totalduration;
		};

		/**
		 *
		 */
		private onLevelSwitched = (type: string, data: LevelSwitchedData): void => {
			const { level } = data;
			// Protect against the case where hls.js switches to a bitrate that is higher than the max level
			// (when the cap changes after a request for a higher bitrate was made)
			const maxLevel = Math.min(level, this.player.maxAutoLevel);
			this.quality = this.qualities.find(quality => quality.index === maxLevel);
			this.delegate.qualityChange(this.quality);
		};

		/**
		 *
		 */
		private onFragLoaded = (type: string, data: FragLoadedData): void => {
			const stats = data.frag.stats;
			this.bandwidth = stats.bwEstimate;
		};

		/**
		 *
		 */
		private onSubtitleTracksUpdated = (type: string, data: SubtitleTracksUpdatedData) => {
			const tracks = data.subtitleTracks
				.filter(track => !track.forced)
				.map((track: any, index: number) => ({
					id: track.id?.toString() || index.toString(),
					language: track.lang,
					kind: track.type.toLowerCase(),
					label: track.name || track.lang,
					default: track.default,
				}));

			this.textTracks = tracks;

			const defaultTrack = tracks.find(track => track.default);
			if (!defaultTrack) {
				this.selectSubtitles(Util.findDefaultTrack(tracks, this.context.textTrackSettings.language));
			}

			this.delegate.textTracksChange(this.textTracks);
		};

		/**
		 *
		 */
		private onSubtitleTrackSwitch = (type: string, data: any) => {
			const id = data.id.toString();
			const textTrack = this.textTracks.find((track: any) => track.id === id);

			if (data.id === -1 || !textTrack || this.textTrack === textTrack) {
				return;
			}

			this.selectSubtitles(textTrack);
		};

		/**
		 *
		 */
		private onAudioTracksUpdated = (type: string, data: AudioTracksUpdatedData) => {
			this.audioTracks = this.player.audioTracks.map((track, index) => ({
				index,
				id: track.id?.toString(),
				type: (track.attrs.CHARACTERISTICS === 'public.accessibility.describes-video') ? AudioTrackType.DESCRIPTION : AudioTrackType.MAIN,
				language: track.lang,
				codec: track.audioCodec,
				label: track.name || track.lang,
				default: track.default,
			}));

			this.audioTrack = this.audioTracks[this.player.audioTrack];

			this.delegate.audioTracksChange(this.audioTracks);

			if (this.player.audioTrack === -1) {
				const audioTrack = Util.findDefaultTrack(this.audioTracks, playerOptions.audioLanguage);
				if (audioTrack) {
					this.player.audioTrack = audioTrack.index;
				}
			}
		};

		/**
		 *
		 */
		private onAudioTrackSwitched = (type: string, data: AudioTrackSwitchedData) => {
			this.delegate.audioTrackChange(this.audioTracks[data.id]);
		};

		/**
		 *
		 */
		private onLoadError = (type: string, data: ErrorData): void => {
			const { fatal } = data;
			const error = this.processError(data);

			if (!fatal) {
				this.error(error);
				return;
			}

			this.player.emit(LOAD_ERROR, LOAD_ERROR, error);
		};

		/**
		 *
		 */
		private onError = (type: string, data: ErrorData): void => {
			this.error(this.processError(data));
		};
	}

	return new HlsjsAdapter(context, options);
}
